<div class="wrapper">
  <div *ngFor="let step of steps; let last = last" class="item-with-separator">
    <div class="item">
      <span class="circle"></span>
      <div>
        <span class="hour">{{ step.hour }} <span class="arrow"></span> </span>
        <span> {{ step?.description | translation }}</span>
      </div>
    </div>
    <div *ngIf="!last" class="separator"></div>
  </div>
</div>
