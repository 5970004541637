import { trigger, animate, style, state, query, transition } from '@angular/animations';

export const bounceAnimation = trigger('bounce', [
  state('bounceIn', style({
    opacity: 0,
    display: 'none'
  })),
  state('bounceOut',   style({
    opacity: 1,
    display: 'block',
  })),
  transition('bounceOut => bounceIn', animate('300ms ease-in')),
  transition('bounceIn => bounceOut', animate('300ms ease-out'))
]);
