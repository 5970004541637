import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TranslationPipe } from '../../pipes/translation.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'cmp-itinerary-step',
    templateUrl: './itinerary-step.component.html',
    styleUrls: ['./itinerary-step.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        TranslationPipe,
    ],
})
export class ItineraryStepComponent implements OnInit {
  @Input() steps: any[];

  ngOnInit() {
    this.steps = _.sortBy(this.steps, step => {
      return step.order;
    });
  }
}
